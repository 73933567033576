import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => (
    <svg className={`icon ${props.additionalClasses}`} focusable="false">
        <use xlinkHref={`#icon-${props.icon}`}></use>
    </svg>
);

Icon.defaultProps = {
    additionalClasses: ''
};

Icon.propTypes = {
    additionalClasses: PropTypes.string,
    icon: PropTypes.string.isRequired
};

export default Icon;
