import Playlist from './Playlist';
import Navigation from './Navigation';
import Content from './Content';

const els = document.querySelectorAll('.js-video-playlist');

for (let el of els) {
    const onPlaylistUpdate = (activeItem) => {
        Content(el, activeItem);
        Navigation(el, activeItem);
    };

    // Init
    el.querySelector('.playlist').addEventListener('update', onPlaylistUpdate);
    Playlist(el, onPlaylistUpdate);
}
