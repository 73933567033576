import React from 'react';

export const GoogleMapsContext = React.createContext({
    googleMaps: null
});

const GoogleMapsProvider = (props) => {
    const [googleMaps, setGoogleMaps] = React.useState();

    React.useEffect(() => {
        const googleScript = document.createElement('script');
        googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyBqAZjN5CuYBbJ6iBXEiVh-XIcXl8nSWMk'}&libraries=geometry,places`;
        window.document.body.appendChild(googleScript);
        googleScript.addEventListener('load', () => {
            setGoogleMaps(
                {
                    googleMapsApi: google.maps,
                    autocompleteService: new google.maps.places.AutocompleteService()
                }
            );
        });
    }, []);

    return (
        <GoogleMapsContext.Provider value={{ ...googleMaps }}>
            {props.children}
        </GoogleMapsContext.Provider>
    );
};

export default GoogleMapsProvider;
