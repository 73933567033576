const Navigation = (el, activeItem) => {
    const prevItem = el.querySelector('.prev-next-navigation__item--prev');
    const nextItem = el.querySelector('.prev-next-navigation__item--next');
    const hiddenItemClass = 'prev-next-navigation__item--hidden';

    const getDataForButtons = (item) => {
        if (item) {
            return {
                title: item.querySelector('.playlist__title').innerHTML,
                target: item.getAttribute('id')
            };
        }
    };

    const updateDataForButtons = (data, item) => {
        const button = item.querySelector('.prev-next-navigation__button');
        button.querySelector('.prev-next-navigation__title').innerHTML = data.title;
        button.setAttribute('aria-controls', data.target);
    };

    const updateNavigation = (activeItem) => {
        const newPrevData = getDataForButtons(activeItem.previousElementSibling);
        const newNextData = getDataForButtons(activeItem.nextElementSibling);

        if (newPrevData) {
            updateDataForButtons(newPrevData, prevItem);
            prevItem.classList.remove(hiddenItemClass);
        } else {
            prevItem.classList.add(hiddenItemClass);
        }

        if (newNextData) {
            updateDataForButtons(newNextData, nextItem);
            nextItem.classList.remove(hiddenItemClass);
        } else {
            nextItem.classList.add(hiddenItemClass);
        }
    };

    const init = () => {
        if (activeItem) {
            updateNavigation(activeItem);
        }
    };

    init();
};

export default Navigation;
