import { lerpExt } from '../../functions/lerp';

class RangeSlider {
    constructor(el) {
        this.step = 0.001;
        this.wrapper = el;
        this.input = el.querySelector('.input');
        this.inputLabel = el.querySelector('.input-label');
        this.formControl = el.querySelector('.form_control');
        this.reset();
        this.input.addEventListener('change', this.handleInputChange.bind(this));
        this.input.addEventListener('input', this.handleInputChange.bind(this));
        el.addEventListener('reset', this.reset.bind(this));
    };

    set isDirty(value) {
        this.input.setAttribute('isDirty', value);
    }

    get isDirty() {
        return this.input.getAttribute('isDirty') === 'true';
    }

    reset (e) {
        this.input.min = 0;
        this.input.max = 1;
        this.input.step = this.step;
        this.input.value = 1;
        this.isDirty = false;
        this.render(this.input, '#D1D2D2', '#277D32');
        this.renderLabels(this.input);
    }

    handleInputChange(event) {
        this.isDirty = true;
        this.render(this.input, '#D1D2D2', '#277D32');
        this.renderLabels(this.input);
    }

    render(input, sliderColor, rangeColor) {
        const rangeDistance = input.max - input.min;
        const rangePosition = input.value;
        input.style.background = `linear-gradient(
        to right,
        ${rangeColor} 0%,
        ${rangeColor} calc(${(rangePosition) / (rangeDistance) * 100}%), 
        ${sliderColor} calc(${(rangePosition) / (rangeDistance) * 100}%), 
        ${sliderColor} 100%)`;
    }

    renderLabels(input, precision = 3) {
        let stepIncrements;
        let steps;
        try {
            stepIncrements = JSON.parse(this.wrapper.dataset.stepIncrements);
            steps = JSON.parse(this.wrapper.dataset.steps);
        } catch (e) {
            console.error('invalid JSON');
        }
        if (!stepIncrements || !steps) {
            return;
        }
        // The most left value on range(units)
        const minValue = steps[0];
        // The most right value on range(units)
        const maxValue = steps[steps.length - 1];
        // Interval width for single step(0-1)
        const stepInterval = 1 / stepIncrements.length;

        const stepIndex = Math.floor(input.value / stepInterval); // Index of handle interval

        const startValue = steps[stepIndex]; // Start value of handle interval(units)
        const endValue = steps[stepIndex + 1] || steps[stepIndex]; // End value of handle interval(units)

        // Handle multiplication value(units)
        const stepIncrement = stepIncrements[stepIndex] || stepIncrements[stepIncrements.length - 1];

        let multValue = lerpExt(startValue, endValue, (input.value - stepIndex * stepInterval) / stepInterval, stepIncrement, precision);
        multValue = Math.min(multValue, maxValue);
        input.dataset.realValue = multValue;

        this.dispatchChange(minValue, maxValue, multValue);

        this.inputLabel.textContent = multValue === maxValue ? `${multValue}+` : multValue;

        const accordionContent = this.inputLabel.closest('.accordion__content');
        if (accordionContent) {
            accordionContent.style.display = 'block';
        }
        const labelContainer = this.inputLabel.parentNode;
        const labelHalfWidth = labelContainer.offsetWidth / 2;
        const formControlWidth = this.formControl.offsetWidth;
        const labelOffset = Math.min(Math.max(this.input.value * formControlWidth, labelHalfWidth), formControlWidth - labelHalfWidth);
        labelContainer.style.left = `${labelOffset}px`;

        if (accordionContent) {
            accordionContent.style.display = '';
        }
    }

    dispatchChange(min, max, input) {
        if (this.isDirty) {
            this.wrapper.dispatchEvent(new CustomEvent('change', { detail:{
                min, max, input, isDirty: this.isDirty
            } }));
        }
    }
}

export default RangeSlider;
