import Cookies from 'js-cookie';

const Cookie = el => {
    const name = el.getAttribute('data-cookie');
    const expires = parseInt(el.getAttribute('data-cookie-expires')) || null;
    const value = el.getAttribute('data-cookie-value') || true;
    const consent = el.getAttribute('data-cookie-consent') || null;
    const cookiebotConsent = window.Cookiebot.consent || null;

    const onClick = () => {
        if (cookiebotConsent && cookiebotConsent[consent]) {
            if (expires !== null) {
                Cookies.set(name, value, { expires: expires });
            } else {
                Cookies.set(name, value);
            }
        }
    };

    const init = () => {
        el.addEventListener('click', onClick);
    };

    init();
};

export default Cookie;
