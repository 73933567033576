const els = document.querySelectorAll('.search-result-count');

function SearchResultCount(el) {
    window.addEventListener('loadMoreUpdate', onUpdate);

    function onUpdate(e) {
        const { searchResultCount } = e.data || {};

        el.innerText = searchResultCount || '';
    }
}

for (let el of els) {
    SearchResultCount(el);
}
