import { setInfoWindowContent } from './index';
import markerPin from '../img/marker-pin.svg';
import markerPinSelected from '../img/marker-pin-selected.svg';
import markerSearch from '../img/marker-search.svg';
import markerCluster from '../img/marker-cluster.svg';
import MarkerClusterer from '@google/markerclusterer';

const getMarkerIconUrl = (markerIcon) => ({
    pin: markerPin,
    pinSelected: markerPinSelected,
    search: markerSearch
}[markerIcon]);

const getMarkerSize = (markerIcon) => ({
    pin: [37, 52],
    pinSelected: [37, 52],
    search: [47, 47]
}[markerIcon]);

const createIcon = (markerIcon, googleMapsApi) => ({
    url: getMarkerIconUrl(markerIcon),
    size: new googleMapsApi.Size(...getMarkerSize(markerIcon)),
    scaledSize: new googleMapsApi.Size(...getMarkerSize(markerIcon)),
    origin: new googleMapsApi.Point(0, 0),
    anchor: new googleMapsApi.Point(0, 0)
});

export const createMarker = (markerIcon, markerData, googleMapsApi, map) => {
    const marker = new googleMapsApi.Marker({
        ...markerData,
        icon: createIcon(markerIcon, googleMapsApi),
        optimized: false,
        map
    });

    return marker;
};

const resetMarkersSelectedState = (markers, googleMapsApi) => {
    markers.forEach((marker) => {
        marker.setIcon(createIcon('pin', googleMapsApi));
    });
};

const addMarkers = (
    map,
    googleMapsApi,
    places,
    onInfoWindowClose,
    onMarkerClick
) => {
    const markers = places.map((place) => {
        const infoWindow = new googleMapsApi.InfoWindow();
        const markerData = {
            position: {
                lat: place.Latitude,
                lng: place.Longitude
            },
            ...place
        };
        const marker = createMarker(
            'pin',
            markerData,
            googleMapsApi,
            map
        );
        infoWindow.setContent(setInfoWindowContent(markerData));
        marker.infoWindow = infoWindow;
        // Event triggered when info window has opened
        googleMapsApi.event.addListener(infoWindow, 'domready', () => {
            resetMarkersSelectedState(markers, googleMapsApi);
            marker.setIcon(createIcon('pinSelected', googleMapsApi));
        });
        googleMapsApi.event.addListener(infoWindow, 'closeclick', (e) => {
            resetMarkersSelectedState(markers, googleMapsApi);
            onInfoWindowClose(e);
        });
        return marker;
    });

    markers.forEach((marker) => {
        googleMapsApi.event.addListener(marker, 'click', () => {
            onMarkerClick(marker);
            // set clicked marker icon to selected
            marker.infoWindow.open(map, marker);
            map.setCenter(marker.getPosition());
        });
    });

    new MarkerClusterer(map, markers, {
        styles: [{
            url: markerCluster,
            height: 62,
            width: 62,
            anchor: [0, 0],
            textColor: '#000000',
            textSize: 12
        }]
    });

    return markers;
};

export default addMarkers;
