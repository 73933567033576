const scrollPage = (destination) => {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

    if (isSmoothScrollSupported) {
        window.scrollTo({
            top: destination,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        /* eslint-disable */
        // For browsers that doesn't support scroll behaviour, we can use jQuery to animate page instead.
        if (jQuery) {
            $('html, body').animate({
                scrollTop: destination
            }, 200);
        } else {
            window.scrollTo(0, destination);
        }
        /* eslint-enable */
    }
};

export default scrollPage;
