import React from 'react';
import PropTypes from 'prop-types';

const Spinner = (props) => (
    <div className={`${props.additionalClasses}`} style={{ width: `${props.size}px`, height: `${props.size}px` }}>
        <div className="spinner" >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

Spinner.defaultProps = {
    additionalClasses: '',
    size: 40
};

Spinner.propTypes = {
    additionalClasses: PropTypes.string,
    size: PropTypes.number
};

export default Spinner;
