import React from 'react';
import { render, createPortal } from 'react-dom';
// components
import FindInstaller from '../components/find-installer/FindInstaller';
import GoogleMap from '../components/google-map/GoogleMap';
import SearchWidget from '../components/search-widget/SearchWidget';
// context
import GoogleMapsProvider from '../context/GoogleMapsProvider';

function ReactMountManager() {
    const appContainers = document.querySelectorAll('div[data-react]');
    const Components = Array.from(appContainers).map((container) => {
        const reactComponent = container.dataset.react;
        const props = container.dataset.props ? JSON.parse(container.dataset.props) : {};

        const Component = {
            'find-installer': FindInstaller,
            'google-map': GoogleMap,
            'search-widget': SearchWidget
        }[reactComponent] || null;

        return createPortal(<Component {...props} />, container);
    });

    return Components;
}

export default () => {
    render(
        <GoogleMapsProvider>
            <ReactMountManager />
        </GoogleMapsProvider>,
        document.getElementById('reactRootNode')
    );
};
