import throttle from 'lodash/throttle';
import { gsap } from 'gsap';

class HorizontalScroller {
    constructor(el, opts) {
        this.el = el;
        this.opts = {
            showScrollIcon: false,
            ...opts
        };
        this.scroller = this.el.querySelector('.horizontal-scroller__scroller');
        this.contentContainer = this.scroller.querySelector(`.horizontal-scroller__scroller > *`);
        this.navigateLeft = this.el.querySelector('.horizontal-scroller__navigate--left');
        this.navigateRight = this.el.querySelector('.horizontal-scroller__navigate--right');
        this.toggleIcon = this.toggleIcon.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.checkOverflow = this.checkOverflow.bind(this);
        this.navigate = this.navigate.bind(this);
        this.scrollPosition = 0;
        this.contentWidth = 0;
        this.hasScroll = false;
        this.scrollPositionStart = true;
        this.scrollPositionEnd = false;
        this.hasNavigation = typeof this.navigateLeft !== 'undefined' && this.navigateLeft !== null &&
            typeof this.navigateRight !== 'undefined' && this.navigateRight !== null;

        this.init();
    }

    init() {
        this.checkOverflow();
        this.checkScrollPosition();
        window.addEventListener('resize', throttle(this.handleResize, 100));
        this.scroller.addEventListener('scroll', throttle(this.handleScroll, 100));

        if (this.hasNavigation) {
            this.navigateLeft.addEventListener('click', () => this.navigate('left'));
            this.navigateRight.addEventListener('click', () => this.navigate('right'));
        }
        this.showHideElementsBasedOnScroll();
    }

    navigate(direction) {
        let targetScrollValue = this.scroller.scrollLeft + this.contentContainer.scrollWidth;

        gsap.to(this.scroller, {
            duration: .3,
            scrollTo: {
                x: direction === 'right' ? targetScrollValue : -targetScrollValue
            }
        });
    }

    checkOverflow() {
        this.isOverflow = this.el.offsetWidth < this.contentContainer.scrollWidth;
        this.checkScrollPosition();

        if (this.opts.showScrollIcon) {
            this.toggleIcon(this.isOverflow);
        }
    }

    handleScroll() {
        this.checkScrollPosition();

        if (this.opts.showScrollIcon) {
            this.toggleIcon(false);
        }
        this.showHideElementsBasedOnScroll();
    }

    handleResize() {
        this.checkScrollPosition();
        this.checkOverflow();
        this.showHideElementsBasedOnScroll();
    }

    showHideElementsBasedOnScroll() {
        // show/hide navigation buttons
        if (this.hasNavigation) {
            this.navigateLeft.classList.toggle(
                'horizontal-scroller__navigate--hidden',
                this.scrollPositionStart
            );
            this.navigateRight.classList.toggle(
                'horizontal-scroller__navigate--hidden',
                this.scrollPositionEnd
            );
        }
        // show/hide overflow indidators
        this.el.classList.toggle(
            'horizontal-scroller--overflow-right',
            !this.scrollPositionEnd
        );
        this.el.classList.toggle(
            'horizontal-scroller--overflow-left',
            !this.scrollPositionStart
        );
    }

    checkScrollPosition() {
        this.scrollPosition = this.scroller.scrollLeft + this.scroller.getBoundingClientRect().width;
        this.contentWidth = this.contentContainer.scrollWidth;
        this.scrollPositionStart = this.scroller.scrollLeft === 0;
        this.scrollPositionEnd = Math.abs(this.scrollPosition - this.contentWidth) < 1;
        this.hasScroll = this.scroller.getBoundingClientRect().width < this.contentWidth;
    }

    toggleIcon(visible) {
        this.el.classList.toggle('horizontal-scroller--show-icon', visible);
    }
}

export default HorizontalScroller;
