const YouTubePlayer = (el) => {
  function initPlayer (videoElement) {
    const videoId = videoElement.dataset.videoId;
    const youtubePlayer = videoElement.querySelector('.video-player__youtube');

    new window.YT.Player(youtubePlayer, {
      videoId: videoId,
      host: 'https://www.youtube-nocookie.com',
      playerVars: {
        autoplay: 1,
        modestbranding: 1,
        rel: 0
      },
      events: {
        'onReady': function(e) {
          onPlayerReady(videoElement);
        }
      }
    });
  }

  function onPlayerReady (videoElement) {
    videoElement.classList.remove('video-player--cover');
    videoElement.setAttribute('aria-busy', false);
  }

  initPlayer(el);
};

export default YouTubePlayer;
