import React from 'react';
import PropTypes from 'prop-types';
import GoogleMap from '../google-map/GoogleMap';
import SearchFieldPlaces from './SearchFieldPlaces';
import MapContactCard from '../map-contact-card/MapContactCard';
import { installersToContactInfoProps } from './utils/parsers';

const MapView = (props) => {
    const [selectedLocationCords, setSelectedLocationCords] = React.useState(props.selectedLocationCords);
    const [places, setPlaces] = React.useState();
    const [visiblePlaces, setVisiblePlaces] = React.useState([]);
    const [selectedPlace, setSelectedPlace] = React.useState('');
    const mapRef = React.useRef();

    const openInfoWindow = (id) => {
        mapRef.current.openInfoWindow(id);
    };

    React.useEffect(() => {
        if (mapRef.current) {
            openInfoWindow(selectedPlace);
        }
    }, [selectedPlace, mapRef]);

    React.useEffect(() => {
        fetch(props.placesEndpoint)
            .then((res) => res.json())
            .then((data) => setPlaces(data.Result));
    }, []);

    return (
        <div className="find-installer-map">
            <aside className="find-installer-map__result">
                <div className="row">
                    <div className="col col--span-12">
                        <div className="find-installer-map__search">
                            <SearchFieldPlaces
                                onSelect={(cords) => setSelectedLocationCords(cords)}
                            />
                        </div>
                    </div>
                </div>
                <div className="find-installer-map__cards">
                    <div className="row">
                        {places
                            ? installersToContactInfoProps(places, selectedLocationCords).map(({ contactInfo, key, title }) => (
                                visiblePlaces.includes(key) &&
                                <div className="col col--span-12 col--span-m-6 col--span-xl-12" key={key}>
                                    <MapContactCard
                                        key={key}
                                        id={String(key)}
                                        title={title}
                                        contactInfo={contactInfo}
                                        active={selectedPlace === key}
                                        onClick={() => setSelectedPlace(key)}
                                    />
                                </div>
                            ))
                            // ToDo: Add loading state
                            : null}
                    </div>
                </div>
            </aside>
            <div className="find-installer-map__map">
                {places
                    ? <GoogleMap
                        ref={mapRef}
                        places={places}
                        targetPlaceCords={selectedLocationCords}
                        onVisibleMarkersUpdate={setVisiblePlaces}
                        onInfoWindowClose={() => setSelectedPlace(() => '')}
                        onMarkerClick={(marker) => setSelectedPlace(() => marker.Id)}
                    />
                    // ToDo: Add loading state
                    : null}
            </div>
        </div>
    );
};

MapView.propTypes = {
    selectedLocationCords: PropTypes.object.isRequired,
    setSearchLatLon: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number
    }),
    placesEndpoint: PropTypes.string.isRequired
};

export default MapView;
