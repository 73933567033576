class ProductFilters {
    constructor(el) {
        this.handleChange = this.handleChange.bind(this);
        this.handleRangeSliderChange = this.handleRangeSliderChange.bind(this);
        this.toggleFiltersNav = this.toggleFiltersNav.bind(this);
        this.el = el;
        this.checkboxes = el.querySelectorAll('input[type="checkbox"]');
        this.checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', this.handleChange);
        });
        this.tagItemList = document.querySelector('.chips-list');
        this.tagBlueprint = document.querySelector('.tag-blueprint');
        this.filtersWrapper = document.querySelector('.product-filters');
        this.overFlowHidden = 'h-overflow-hidden';
        this.filtersMobileActive = 'filters-mobile-active';
        this.filtersOpenButton = document.querySelector('.product-filters__open-button');
        this.filtersCloseButton = document.querySelector('.product-filters__close-button');
        this.viewFiltersButton = document.querySelector('.view-products-button');
        this.clearFilterButton = document.querySelector('.js-clear-filters');
        this.rangeSliders = document.querySelectorAll('.js-range-slider');
        this.rangeSliders.forEach((slider) => {
            slider.addEventListener('change', this.handleRangeSliderChange);
        });
        this.init();
    }

    calculateFraction(value, minValue, maxValue) {
        // Ensure that the value is not less than minValue and not greater than maxValue
        value = Math.min(maxValue, Math.max(minValue, value));
        // Calculate the fraction
        const fraction = (value - minValue) / (maxValue - minValue);
        return fraction;
    }

    handleChange(event) {
        const checkbox = event.target;
        const checkboxText = checkbox.getAttribute('data-text');
        const accordion = checkbox.closest('.accordion');
        const checkedInputs = accordion.querySelectorAll('input:checked');
        const counterContainer = accordion.querySelector('.accordion__counter');

        counterContainer.style.display = checkedInputs.length > 0 ? 'inline-flex' : '';
        counterContainer.innerHTML = checkedInputs.length;

        if (!checkbox.checked) {
            const checkedInputEl = this.tagItemList.querySelector('[data-text="' + checkboxText + '"]');
            checkedInputEl && this.tagItemList.removeChild(checkedInputEl);
        } else {
            const li = this.tagBlueprint.cloneNode(true);
            li.classList.remove('tag-blueprint');
            li.setAttribute('data-text', checkboxText);
            li.querySelector('.chips__text').innerHTML = checkboxText;
            li.addEventListener('click', () => {
                checkbox.checked = false;
                checkbox.dispatchEvent(new Event('change', { bubbles: true }));
            }, { once: true });
            this.tagItemList.appendChild(li);
        }
        const listItemsArray = this.tagItemList.querySelectorAll('li:not(.tag-blueprint)');
        this.clearFilterButton.style.display = listItemsArray.length ? 'inline-block' : '';
    }

    buildChipsList() {
        const savedFiltersArray = sessionStorage.getItem('filters array');
        const savedFiltersDictionary = sessionStorage.getItem('filters dictionary');
        const filtersArray = savedFiltersArray ? JSON.parse(savedFiltersArray) : [];
        const filtersDictionary = savedFiltersDictionary ? JSON.parse(savedFiltersDictionary) : {};

        if (filtersDictionary && Object.keys(filtersDictionary).length > 0) {
            let sliderIds = Object.keys(filtersDictionary);
            sliderIds.forEach((sliderId) => {
                const switcher = document.querySelector(`input[type="radio"][value="${sliderId}"]`);
                const slider = switcher && switcher.closest('.js-range-slider');
                if (!slider) return;
                switcher.checked = true;
                switcher.dispatchEvent(new Event('click', { bubbles: true }));

                const steps = JSON.parse(slider.dataset.steps);
                const stepIncrements = JSON.parse(slider.dataset.stepIncrements);

                let [amount] = filtersDictionary[sliderId].split('|');
                amount = parseFloat(amount);

                // Interval width for single step(0-1)
                const stepInterval = 1 / stepIncrements.length;

                const stepIndex = steps.findLastIndex((step) => step <= amount);

                const startValue = steps[stepIndex]; // Start value of handle interval(units)
                const endValue = steps[stepIndex + 1] || steps[stepIndex]; // And value of handle interval(units)

                let handlePosition = stepIndex * stepInterval + ((amount - startValue) / (endValue - startValue) || 0) * stepInterval;

                const input = slider.querySelector('.input');
                input.value = handlePosition;
                input.dispatchEvent(new Event('change', { bubbles: true }));
            });
        }

        if (filtersArray && filtersArray.length > 0) {
            for (let i = 0; i < filtersArray.length; i++) {
                const filterItem = filtersArray[i];
                const targetCheckbox = Array.from(this.checkboxes).find(checkbox => checkbox.value === filterItem.value);

                if (targetCheckbox) {
                    targetCheckbox.setAttribute('data-should-update-filters', i === filtersArray.length - 1);
                    targetCheckbox.checked = true;
                    targetCheckbox.dispatchEvent(new Event('change', { bubbles: true }));
                    targetCheckbox.removeAttribute('data-should-update-filters');
                }
            }
        }
    }

    handleRangeSliderChange(event) {
        if (!event.target.classList.contains('js-range-slider')) return;
        const rangeSliderElement = event.target;
        const rangeSliderTitle = rangeSliderElement.dataset.title;
        const { max, input, isDirty } = event.detail || {};

        const accordion = event.target.closest('.accordion');
        const counterContainer = accordion.querySelector('.accordion__counter');

        counterContainer.style.display = isDirty ? 'inline-flex' : '';
        counterContainer.innerHTML = 1;

        if (event.detail) {
            const rangeSliderText = `${rangeSliderTitle}: ${input}${input === max ? '+' : ''}`;
            let li = this.tagItemList.querySelector('[data-range-slider-id="' + rangeSliderElement.dataset.rangeSliderId + '"]');
            if (!li) {
                const li = this.tagBlueprint.cloneNode(true);
                li.classList.remove('tag-blueprint');
                li.setAttribute('data-text', rangeSliderText);
                li.dataset.rangeSliderId = rangeSliderElement.dataset.rangeSliderId;
                li.querySelector('.chips__text').innerHTML = rangeSliderText;
                li.addEventListener('click', () => {
                    rangeSliderElement.dispatchEvent(new Event('reset'));
                    rangeSliderElement.dispatchEvent(new Event('change', { bubbles: true }));
                }, { once: true });
                this.tagItemList.appendChild(li);
            } else {
                li.setAttribute('data-text', rangeSliderText);
                li.dataset.rangeSliderId = rangeSliderElement.dataset.rangeSliderId;
                li.querySelector('.chips__text').innerHTML = rangeSliderText;
            }

        } else {
            const rangeSliderChip = this.tagItemList.querySelector('[data-range-slider-id="' + rangeSliderElement.dataset.rangeSliderId + '"]');
            rangeSliderChip && this.tagItemList.removeChild(rangeSliderChip);
        }

        const listItemsArray = this.tagItemList.querySelectorAll('li:not(.tag-blueprint)');
        this.clearFilterButton.style.display = listItemsArray.length ? 'inline-block' : '';
    }

    toggleFiltersNav() {
		if (!document.body.classList.contains(this.overFlowHidden)) {
            this.scrollTop = document.scrollingElement.scrollTop;
			document.body.classList.add(this.overFlowHidden);
            this.filtersWrapper.classList.add(this.filtersMobileActive);
			document.body.style.top = `-${this.scrollTop}px`;
		} else {
			document.body.classList.remove(this.overFlowHidden);
            this.filtersWrapper.classList.remove(this.filtersMobileActive);
            document.body.style.top = '';
			document.scrollingElement.scrollTop = this.scrollTop;
		}
	}

    init() {
        this.filtersOpenButton.addEventListener('click', this.toggleFiltersNav);
        this.filtersCloseButton.addEventListener('click', this.toggleFiltersNav);
        this.viewFiltersButton.addEventListener('click', this.toggleFiltersNav);
        setTimeout(() => this.buildChipsList(), 300);
    }
}

export default ProductFilters;
