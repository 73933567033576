import { gsap } from 'gsap';
import isInViewport from '../../functions/isInViewport';
const els = document.querySelectorAll('.accordion');

for (let el of els) {
    const button = el.querySelector('.accordion__button');
    const content = document.getElementById(button.getAttribute('aria-controls'));

    button.addEventListener('click', () => {
        const expanded = button.getAttribute('aria-expanded') === 'true';

        button.setAttribute('aria-expanded', !expanded);
        content.setAttribute('aria-hidden', expanded);
        expanded ? el.classList.remove('accordion--open') : el.classList.add('accordion--open');

        if (!expanded && !isInViewport(content)) {
            gsap.to(window, { duration: 0.3, scrollTo: button });
        }
    });
}
