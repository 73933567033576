import { gsap } from 'gsap';
import getEvent from '../../functions/getEvent';
import isInViewport from '../../functions/isInViewport';

const Content = (el, activeItem) => {
    const videoPlayer = el.querySelector('.video-player');
    const showMoreEl = el.querySelector('.show-more');

    const updateContent = (activeItem) => {
        const newTitle = activeItem.querySelector('.playlist__title').innerHTML;
        const newBody = activeItem.getAttribute('data-content');

        el.querySelector('.js-video-playlist-title').innerHTML = newTitle;
        el.querySelector('.js-video-playlist-body').innerHTML = newBody;

        updateVideoPlayer(activeItem);
        updateShowMore();
        isContentInView();
    };

    const updateVideoPlayer = (activeItem) => {
        const event = getEvent('update');
        const obj = {
            id: activeItem.getAttribute('data-video-id'),
            coverImage: activeItem.getAttribute('data-video-cover'),
            label: activeItem.getAttribute('data-video-label')
        };
        event.data = obj;

        videoPlayer.dispatchEvent(event);
    };

    const updateShowMore = () => {
        const event = getEvent('update');
        showMoreEl.dispatchEvent(event);
    };

    const isContentInView = () => {
        if (!isInViewport(videoPlayer)) {
            gsap.to(window, { duration: 0.3, scrollTo: { y: videoPlayer, offsetY: 16 } });
        };
    };

    const init = () => {
        if (activeItem) {
            updateContent(activeItem);
        }
    };

    init();
};

export default Content;
