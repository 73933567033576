import React from 'react';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import Icon from '../icon';

const SearchField = (props) => {
    const {
        preSelectedFirstItem,
        onInputChange,
        onSubmit,
        onChange,
        itemStyle,
        items,
        itemComponent,
        className,
        inputPlaceholder,
        seeMoreLink,
        seeMoreText
    } = props;

    function stateReducer(state, changes) {
        // this prevents the menu from being closed when the user
        // selects an item with a keyboard or mouse
        switch (changes.type) {
            case Downshift.stateChangeTypes.blurInput:
                return {
                    ...changes,
                    ...(items[state.highlightedIndex] && { inputValue: items[state.highlightedIndex].value }),
                    ...(typeof onSubmit !== 'undefined' && { inputValue: state.inputValue }),
                    ...(onSubmit === 'undefined' && { highlightedIndex: state.highlightedIndex })
                };
        default:
            return changes;
        }
    }

    return (
        <form onSubmit={(e) => onSubmit && onSubmit(e)}>
            <Downshift
                itemToString={item => (item ? item.value : ``)}
                defaultHighlightedIndex={preSelectedFirstItem ? 0 : null}
                stateReducer={stateReducer}
                {...props}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex
                }) => (
                    <div className={`search-field ${className}`}>
                        <div className="search-field__field">
                            <input
                                name="q"
                                className="search-field__input"
                                placeholder={inputPlaceholder}
                                type="search"
                                {...getInputProps({
                                    onChange: e => onInputChange(e)
                                })}
                            />
                            <button
                                className="search-field__submit"
                                type="submit"
                                onClick={(e) => {
                                    if (typeof items[highlightedIndex] !== 'undefined') {
                                        onChange(items[highlightedIndex]);
                                        e.preventDefault();
                                    } else if (typeof onSubmit === 'undefined') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            {props.children}
                        </div>
                        {isOpen && inputValue.length > 0 && items.length > 0
                        ? (
                            <React.Fragment>
                                <ul
                                    className={`search-field__autocomplete ${isOpen &&
                                        `search-field__autocomplete--is-open`}`}
                                    {...getMenuProps()}
                                >
                                    {items.map((item, index) => (
                                            <li
                                                className={`search-field__item ${highlightedIndex ===
                                                    index &&
                                                    `search-field__item--selected`} ${itemStyle}`}
                                                {...getItemProps({
                                                    key: index,
                                                    index,
                                                    item
                                                })}
                                            >
                                                {itemComponent(item)}
                                            </li>
                                        ))}
                                    {
                                        seeMoreLink && seeMoreLink.length > 0 &&
                                        <li className='search-field__item search-field__item--link-holder'>
                                            <a className='search-field__item--link' href={`${seeMoreLink}?qp=${inputValue}`}>
                                                <span>{seeMoreText}</span>
                                                <Icon icon='chevron-right' additionalClasses="search-field__item--icon" />
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </React.Fragment>
                        ) : null}
                    </div>
                )}
            </Downshift>
        </form>
    );
};

SearchField.propTypes = {
    onInputChange: PropTypes.func,
    onSubmit: PropTypes.func,
    itemStyle: PropTypes.string,
    items: PropTypes.array.isRequired,
    itemComponent: PropTypes.func,
    className: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    preSelectedFirstItem: PropTypes.bool,
    seeMoreLink: PropTypes.string
};

SearchField.defaultProps = {
    onInputChange: () => {},
    itemStyle: '',
    className: '',
    preSelectedFirstItem: true,
    seeMoreLink: ''
};

export default SearchField;
