const getEvent = name => {
    let event;

    try {
        event = new CustomEvent(name, { bubbles: true });
    } catch (e) {
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

    return event;
};

export default getEvent;
