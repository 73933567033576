// libs
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

// Polyfill
import 'isomorphic-fetch';
import 'element-closest-polyfill';

// External
import 'focus-visible';

// Internal
import ReactMountManager from './core/ReactMountManager';
import './functions/TabLoop';
import './functions/load-more';
import './functions/select-submit';
import './functions/cookie';
import './functions/video-playlist';
import './functions/data-layer-tracking';

import './chrome/header';
import './chrome/mega-menu/';
import './chrome/subnav/';

import './components/modal';
import './components/horizontal-scroller';
import './components/search-result-count';
import './components/carousel';
import './components/show-more';
import './components/show-info';
import './components/youtube-player';
import './components/filters';
import './components/product-filters';
import './components/options-limiter';
import './components/top-bar';
import './components/tabs';
import './components/interactive-image-tabs';
import './components/accordion';
import './components/panel';
import './components/form/select-multiple';
import './components/tooltip';
import './components/expanded-rte-trigger';
import './components/range-slider/';
import './components/switchers/';
import './components/custom-scroller';

import './vendor/HubSpot';

// Init
ReactMountManager();
gsap.registerPlugin(ScrollToPlugin);
