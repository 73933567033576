import Swiper from 'swiper';

class Carousel {
    constructor(el) {
        this.el = el;
        this.carouselSelector = this.el.querySelector('.js-carousel');
        this.carouselItems = this.carouselSelector.querySelectorAll('.js-carousel__item');
        this.thumbnailCarouselSelector = this.el.querySelector('.js-thumbnail-carousel');
        this.thumbnails = this.thumbnailCarouselSelector.querySelectorAll('.js-thumbnail-item');
        this.init();
    }

    init() {
        const self = this;
        this.carousel = new Swiper(this.carouselSelector, {
            effect: 'fade',
            fadeEffect: { crossFade: true },
            on: {
                'slideChange': function () {
                    const index = this.activeIndex;
                    self.highlightActiveThumbnail(index);
                }
            }
        });
        this.thumbnailCarousel = new Swiper(this.thumbnailCarouselSelector, {
            slidesPerView: 2,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                480: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 4
                }
            }
        });

        this.attachTriggers();
        this.highlightActiveThumbnail(0);
        }

    attachTriggers() {
        for (let i = 0; i < this.thumbnails.length; i++) {
            const thumbnail = this.thumbnails[i];

            thumbnail.addEventListener('click', () => {
                this.goToSlide(i);
                // Selected video
                const video = this.carouselItems[i].querySelector('.video');
                // All videos
                const videos = this.carouselSelector.querySelectorAll('.video');
                if (video) {
                    // Pause all videos
                    videos.forEach(video => {
                        video.pause();
                    });
                    // Play the selected video
                    video.play();
                } else {
                    // Pause all videos
                    videos.forEach(video => {
                        video.pause();
                    });
                }
            });
        }
    }

    highlightActiveThumbnail(index) {
        const selectedThumbnail = this.thumbnails[index];
        const activeThumbnail = this.thumbnailCarouselSelector.querySelector('.js-thumbnail-item.carousel__item--active');

        selectedThumbnail.classList.add('carousel__item--active');

        const activeImage = selectedThumbnail.querySelector('img');
        for (let i = 0; i < this.thumbnails.length; i++) {
            this.thumbnails[i].querySelector('img').removeAttribute('data-testid');
        }
        activeImage.setAttribute('data-testid', 'img-active');

        if (activeThumbnail) {
            activeThumbnail.classList.remove('carousel__item--active');
        }
    }

    goToSlide(index) {
        this.carousel.slideTo(index);
    }
}

export default Carousel;
