import React from 'react';
import PropTypes from 'prop-types';
import StartView from './StartView';
import MapView from './MapView';

const FindInstaller = (props) => {
    const [selectedLocationCords, setSelectedLocationCords] = React.useState();

    const onSelect = (cords) => setSelectedLocationCords(() => cords);

    return (
        <>
            {selectedLocationCords
                ? <MapView
                    selectedLocationCords={selectedLocationCords}
                    placesEndpoint={props.placesEndpoint}
                />
                : <StartView
                    lang={props.lang}
                    onSelect={onSelect}
                />
            }
        </>
    );
};

FindInstaller.propTypes = {
    placesEndpoint: PropTypes.string.isRequired
};

export default FindInstaller;
