import React, { forwardRef, useRef, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import mapOptions from './mapOptions';
import { addZoomControl, addMarkers, createMarker } from './utils';
import { GoogleMapsContext } from '../../context/GoogleMapsProvider';

const GoogleMap = forwardRef((props, ref) => {
    const mapRef = useRef();
    const [ map, setMap ] = React.useState();
    const [ targetMarker, setTargetMarker ] = React.useState(); // eslint-disable-line
    const [ markers, setMarkers ] = React.useState();
    const { googleMapsApi } = React.useContext(GoogleMapsContext);

    const initGoogleMap = () => {
        setMap(() => new googleMapsApi.Map(mapRef.current, mapOptions));
    };

    const getPlaceLatLng = (place) => {
        return (new googleMapsApi.LatLng(
            place.Latitude,
            place.Longitude
        ));
    };

    const getVisiblePlaces = () => {
        var bounds = map.getBounds();

        return (
            props.places.reduce((acc, place) => {
                const isPlaceVisible = bounds.contains(getPlaceLatLng(place));
                return ([
                    ...acc,
                    ...(isPlaceVisible ? [place.Id] : [])
                ]);
            }, [])
        );
    };

    const getTargetArea = (targetLatLng) => {
        const distance = window.innerWidth > 767 ? 30000 : 15000; // Change radius between different viewports.

        return googleMapsApi.geometry.spherical.computeOffset(targetLatLng, distance, 0);
    };

    const findClosestMarker = (targetLatLng) => {
        var distances = [];
        var closest = -1;
        for (let i = 0; i < props.places.length; i++) {
            var d = googleMapsApi.geometry.spherical.computeDistanceBetween(
                targetLatLng,
                getPlaceLatLng(props.places[i])
            );
            distances[i] = d;
            if (closest === -1 || d < distances[closest]) {
                closest = i;
            }
        }
        return new googleMapsApi.LatLng(
            props.places[closest].Latitude,
            props.places[closest].Longitude
        );
    };

    const updateTargetMarker = (lonLat) => {
        setTargetMarker((prevMarker) => {
            // remove previous marker
            if (prevMarker) {
                prevMarker.setMap(null);
            }
            return createMarker(
                'search',
                {
                    position: lonLat
                },
                googleMapsApi,
                map
            );
        });
    };

    const openInfoWindow = (id) => {
        markers.forEach(marker => {
            marker.infoWindow.close(map, marker);
        });
        const marker = markers.find((marker) => marker.Id === id);
        if (marker) {
            marker.infoWindow.open(map, marker);
            map.setCenter(marker.getPosition());
        }
    };

    useImperativeHandle(ref, () => ({
        openInfoWindow
    }));

    // Initialize map when ready
    useEffect(() => {
        const _mapRef = mapRef.current;
        if (googleMapsApi && _mapRef) {
            initGoogleMap();
        }
    }, [googleMapsApi, mapRef]);

    // Set map focus based on a given tartget place
    useEffect(() => {
        // break execution if targetPlaceCords are undefined
        if (!props.targetPlaceCords) {
            return;
        }
        if (googleMapsApi && map) {
            const targetLatLng = props.targetPlaceCords;
            const bounds = new googleMapsApi.LatLngBounds();

            bounds.extend(targetLatLng);
            bounds.extend(getTargetArea(targetLatLng));
            map.fitBounds(bounds);

            const listener = google.maps.event.addListener(map, 'idle', () => {
                map.setCenter(targetLatLng);

                const isAnyPlaceVisible = getVisiblePlaces(targetLatLng);
                // If map doesn't contains any markers then find closest one.
                if (!isAnyPlaceVisible.length) {
                    bounds.extend(findClosestMarker(targetLatLng));
                    map.fitBounds(bounds);
                }

                google.maps.event.removeListener(listener);
            });
            updateTargetMarker(targetLatLng);
        }
    }, [props.targetPlaceCords, map]);

    useEffect(() => {
        if (map) {
            addZoomControl(map);
            if (props.places) {
                setMarkers(() => addMarkers(map, googleMapsApi, props.places, props.onInfoWindowClose, props.onMarkerClick));
                googleMapsApi.event.addListener(map, 'idle', function() {
                    props.onVisibleMarkersUpdate(getVisiblePlaces());
                });
            }
        }
    }, [map]);

    return (
        <div ref={mapRef} className="google-map__map" />
    );
});

GoogleMap.displayName = 'GoogleMap';

GoogleMap.defaultProps = {
    onVisibleMarkersUpdate: () => {},
    onInfoWindowClose: () => {},
    onMarkerClick: () => {}
};

GoogleMap.propTypes = {
    targetPlaceCords: PropTypes.object,
    onVisibleMarkersUpdate: PropTypes.func,
    onMarkerClick: PropTypes.func
};

export default GoogleMap;
