class ShowMore {
    constructor(showMore) {
        this.showMore = showMore;
        this.showMoreContent = showMore.querySelector('.js-show-more-content');
        this.showMoreBtnHolder = showMore.querySelector('.js-show-more-btn-holder');
        this.showMoreBtn = this.showMoreBtnHolder.querySelector('.js-show-more-btn');
        this.showMoreText = this.showMoreBtn.innerHTML;
        this.hideText = this.showMoreBtn.dataset.hideText;
        this.init();
    }

    init() {
        this.addEventListeners();
        this.displayShowMoreBtn();
    }

    showMoreBtnNeeded() {
        const maxContentHeight = parseInt(window.getComputedStyle(this.showMoreContent, null).getPropertyValue('max-height'));
        const actualContentHeight = this.showMoreContent.scrollHeight;

        if (actualContentHeight > maxContentHeight) {
            return true;
        }
        return false;
    }

    displayShowMoreBtn() {
        this.showMoreBtnNeeded()
            ? this.showMoreBtnHolder.classList.add('show-more__btn-holder--visible')
            : this.showMoreBtnHolder.classList.remove('show-more__btn-holder--visible');
    }

    addEventListeners() {
        this.showMoreBtn.addEventListener('click', () => {
            this.showMore.classList.toggle('show-more--active');
            this.showMoreBtn.innerHTML = this.showMore.classList.contains('show-more--active') ? this.hideText : this.showMoreText;
        });

        this.showMore.addEventListener('update', () => {
            this.showMore.classList.remove('show-more--active');
            this.showMoreBtn.innerHTML = this.showMoreText;
            this.displayShowMoreBtn();
        });
    }
}

export default ShowMore;
