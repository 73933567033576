const MegaMenu = el => {
    const menus = el.querySelectorAll('[aria-hidden]');
    const triggers = document.querySelectorAll('.primary-nav__link[aria-controls]');
    const MegaMenuListener = (e) => onKeyDown(e);
    const MegaMenuCloseBtnListener = () => onClose();
    const triggerActiveClass = 'primary-nav__menu-item--active';
    let isOpen = false;
    let activeTrigger = null;
    let activeMenu = null;
    let closeBtn = null;
    let firstFocusableElement = null;
    let lastFocusableElement = null;

    const onClick = e => {
        e.preventDefault();
        const { trigger, current } = e.data;

        activeTrigger = trigger;
        activeMenu = current;

        if (!isOpen) {
            open();
        } else {
            if (activeMenu.getAttribute('aria-hidden') === 'false') {
                close();
            } else {
                toggle();
            }
        }
    };

    const onKeyDown = e => {
        if (e.keyCode === 27) {
            window.removeEventListener('keydown', MegaMenuListener);

            close(activeMenu);
        }

        if (e.shiftKey && e.keyCode === 9) {
            if (document.activeElement === firstFocusableElement) {
                lastFocusableElement.focus();
                e.preventDefault();
            }
        } else {
            if (e.keyCode === 9) {
                if (document.activeElement === lastFocusableElement) {
                    firstFocusableElement.focus();
                    e.preventDefault();
                }
            }
        }
    };

    const open = () => {
        isOpen = true;
        activeMenu.setAttribute('aria-hidden', false);
        activeTrigger.parentNode.classList.add(triggerActiveClass);
        tabTrap(activeMenu);

        window.addEventListener('keydown', MegaMenuListener);

        closeBtn = activeMenu.querySelector('.mega-menu__close');
        closeBtn.addEventListener('click', MegaMenuCloseBtnListener);
    };

    const close = () => {
        isOpen = false;
        activeMenu.setAttribute('aria-hidden', true);
        activeTrigger.focus();
        activeTrigger.parentNode.classList.remove(triggerActiveClass);
        closeBtn.removeEventListener('click', MegaMenuCloseBtnListener);
    };

    const toggle = () => {
        for (let menu of menus) {
            menu.setAttribute('aria-hidden', true);
        }

        for (let trigger of triggers) {
            trigger.parentNode.classList.remove(triggerActiveClass);
        }

        activeTrigger.parentNode.classList.add(triggerActiveClass);
        activeMenu.setAttribute('aria-hidden', false);
        tabTrap(activeMenu);
    };

    const tabTrap = () => {
        const focusableElements = activeMenu.querySelectorAll('a', 'button');
        firstFocusableElement = focusableElements[0];
        lastFocusableElement = focusableElements[focusableElements.length - 1];

        firstFocusableElement.focus();
    };

    const onClose = () => {
        close(activeMenu);
    };

    const init = () => {
        el.addEventListener('triggerMegaMenu', onClick);
    };

    init();
};

export default MegaMenu;
