import YouTubePlayer from './YouTubePlayer';

const els = document.querySelectorAll('.video-player');
const youtubeScriptId = 'youtube-api';
let isYouTubeIframeAPILoaded = false;

function loadYoutubePlayer(el) {
    // Check if Cookiebot exist, has consent and marketing is not approved.
    if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing !== true) {
        const wc = window.Cookiebot;
        const overlay = el.querySelector('.video-player__overlay');
        const overlayBtn = overlay.querySelector('.video-player__overlay-button');
        const overlayVisibleClass = 'video-player__overlay--visible';
        const cookiebotDialogEl = document.getElementById('CybotCookiebotDialog') || document.createElement('div');
        const consent = wc.consent;

        // Show overlay
        overlay.classList.add(overlayVisibleClass);

        // Add click event to approve button.
        overlayBtn.addEventListener('click', () => {

            // Hide all visible overlays.
            const visibleOverlays = document.querySelectorAll(`.${overlayVisibleClass}`);
            for (let visibleOverlay of visibleOverlays) {
                visibleOverlay.classList.remove(overlayVisibleClass);
            }

            // Manually approve Cookiebot consent marketing and keeps the rest of consent data.
            // submitCustomConsent(optinPreferences, optinStatistics, optinMarketing)
            // Documentation: https://www.cookiebot.com/en/developer/
            wc.submitCustomConsent && wc.submitCustomConsent(consent.preferences, consent.statistics, true);

            // Hides Cookiebots dialog if it's visible.
            if (cookiebotDialogEl && cookiebotDialogEl.style && cookiebotDialogEl.style.display === 'block') {
                wc.hide && wc.hide();
            }

            // Load/reload YoutubePlayer.
            loadYoutubePlayer(el);
        });
    } else {
        // Start Youtube player loader.
        el.setAttribute('aria-busy', true);

        // Load Youtube API.
        if (!isYouTubeIframeAPILoaded) {
            const tag = document.createElement('script');
            const firstScript = document.getElementsByTagName('script')[0];
            tag.src = 'https://www.youtube.com/iframe_api';
            tag.id = youtubeScriptId;
            firstScript.parentNode.insertBefore(tag, firstScript);
        }

        // Playing video when API is loaded and ready.
        if (!isYouTubeIframeAPILoaded) {
            window.onYouTubeIframeAPIReady = () => {
                YouTubePlayer(el);
                isYouTubeIframeAPILoaded = true;
            };
        } else {
            YouTubePlayer(el);
        }
    }
};

function updateVideoPlayerContent(el, data) {
    const videoHolderClass = 'video-player__youtube';
    const videoHolder = el.querySelector(`.${videoHolderClass}`);
    const videoPlayerCover = el.querySelector('.video-player__cover');
    const durationElement = el.querySelector('.video-player__label');

    if (videoHolder) {
        // Remove Iframe/div.
        videoHolder.parentNode.removeChild(videoHolder); // IE11 support for remove();

        // Add new video player div.
        const wrapper = document.createElement('div');
        wrapper.className = videoHolderClass;

        // Update Cover image.
        el.classList.add('video-player--cover');
        videoPlayerCover.parentNode.insertBefore(wrapper, videoPlayerCover);

        // Update video id
        el.setAttribute('data-video-id', data.data.id);

        // Update Cover image
        videoPlayerCover.style.backgroundImage = `url(${data.data.coverImage})`;

        // Update duration time
        durationElement.innerHTML = data.data.label;
    }
}

for (let el of els) {
    const trigger = el.querySelector('.video-player__cover');

    trigger.addEventListener('click', () => {
        loadYoutubePlayer(el);
    });

    el.addEventListener('update', (data) => {
        updateVideoPlayerContent(el, data);
    });
}
