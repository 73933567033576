const DataLayerTracking = () => {
    const primaryNavLinks = document.querySelectorAll('.primary-nav__link');
    const megaMenuLinks = document.querySelectorAll('.mega-menu .mega-menu__list-item .link');
    const secondaryNavLinks = document.querySelectorAll('.secondary-nav__link');
    const logoLink = document.querySelector('.header__logo');
    const flagListLinks = document.querySelectorAll('.flag-list__link');
    const footerNavLinks = document.querySelectorAll('.footer__top-link-list > li > a');
    const filterTables = document.querySelectorAll('.js-filters table');

    const init = () => {
        window.dataLayer = window.dataLayer || [];
        for (let i = 0; i < primaryNavLinks.length; i++) {
            const primaryNavLink = primaryNavLinks[i];
            if (primaryNavLink) {
                primaryNavLink.addEventListener('click', () => {
                    window.dataLayer.push({
                        event: 'main_menu_navigation',
                        main_category: primaryNavLink.textContent.trim()
                    });
                });
            }
        }

        for (let i = 0; i < megaMenuLinks.length; i++) {
            const megaMenuLink = megaMenuLinks[i];
            if (megaMenuLink) {
                megaMenuLink.addEventListener('click', () => {
                    const categoryList = megaMenuLink.closest('.mega-menu__list');
                    const megaMenuItem = megaMenuLink.closest('.mega-menu__item');
                    let subCategoryLink = null;
                    let mainCategoryLink = null;
                    if (megaMenuItem) {
                        mainCategoryLink = megaMenuItem.querySelector('.mega-menu__header');
                    }
                    if (categoryList) {
                        subCategoryLink = categoryList.firstElementChild;
                    }
                    window.dataLayer.push({
                        event: 'main_menu_navigation',
                        main_category: mainCategoryLink ? mainCategoryLink.textContent.trim() : '',
                        sub_category: subCategoryLink ? subCategoryLink.textContent.trim() : '',
                        content: megaMenuLink.textContent.trim()
                    });
                });
            }
        }

        for (let i = 0; i < secondaryNavLinks.length; i++) {
            const secondaryNavLink = secondaryNavLinks[i];
            if (secondaryNavLink) {
                secondaryNavLink.addEventListener('click', () => {
                    window.dataLayer.push({
                        event: 'main_menu_navigation',
                        content: secondaryNavLink.textContent.trim()
                    });
                });
            }
        }

        if (logoLink) {
            logoLink.addEventListener('click', () => {
                window.dataLayer.push({
                    event: 'logo_navigation'
                });
            });
        }

        for (let i = 0; i < flagListLinks.length; i++) {
            const flagListLink = flagListLinks[i];
            if (flagListLink) {
                flagListLink.addEventListener('click', () => {
                    window.dataLayer.push({
                        event: 'country_navigation',
                        country: flagListLink.textContent.trim()
                    });
                });
            }
        }

        for (let i = 0; i < footerNavLinks.length; i++) {
            const footerNavLink = footerNavLinks[i];
            if (footerNavLink) {
                footerNavLink.addEventListener('click', () => {
                    window.dataLayer.push({
                        event: 'footer_navigation',
                        main_category: footerNavLink.textContent.trim()
                    });
                });
            }
        }

        for (let i = 0; i < filterTables.length; i++) {
            const filterTable = filterTables[i];
            if (filterTable) {
                filterTable.addEventListener('click', (e) => {
                    if ((e.target.tagName === 'A' && e.target.classList.contains('js-document-tracking-link')) ||
                        (e.target.closest('a') && e.target.closest('a').classList.contains('js-document-tracking-link'))) {
                        const trackingRow = e.target.closest('tr.js-document-tracking-row');
                        const link = e.target.closest('a.js-document-tracking-link');

                        if (trackingRow && link) {
                            window.dataLayer.push({
                                event: 'file_download',
                                document_category: trackingRow.getAttribute('data-document-category'),
                                file_name: trackingRow.getAttribute('data-filename'),
                                file_extension: trackingRow.getAttribute('data-extension'),
                                click_type: link.textContent.trim() ? 'text' : 'download_button',
                                click_text: link.textContent.trim()
                            });
                        }
                    }
                });
            }
        }
    };

    init();
};

export default DataLayerTracking;
