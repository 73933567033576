class OptionsLimiter {
    constructor(el) {
        const optionsLimitedListWrapper = el;
        const optionSelector = optionsLimitedListWrapper.dataset.optionSelector || '.filter-checkbox-list__item';
        const optionLimit = Number(optionsLimitedListWrapper.dataset.optionLimit || 10);
        const isDesktopEnabled = !optionsLimitedListWrapper.dataset.desktopDisabled;

        if (window.matchMedia('(min-width: 640px)').matches && !isDesktopEnabled) {
            return;
        }
        const optionsLimitedList = optionsLimitedListWrapper.querySelectorAll(optionSelector);
        optionsLimitedList.forEach((option, index) => {
            if (index >= optionLimit) {
                option.classList.add('js-option--hidden');
            }
        });

        if (optionsLimitedList.length > optionLimit) {
            optionsLimitedListWrapper.classList.add('js-options-limiter--padded');
            const buttonWrapper = el.querySelector('.button-wrapper');
            const button = el.querySelector('.show-more-filters');
            buttonWrapper.classList.add('button-wrapper--visible');
            button.addEventListener('click', () => {
                optionsLimitedList.forEach((option, index) => {
                    option.classList.remove('js-option--hidden');
                });
                buttonWrapper.classList.remove('button-wrapper--visible');
                optionsLimitedListWrapper.classList.remove('js-options-limiter--padded');
            });
        }
    }
}

export default OptionsLimiter;
