// Hide Youtube wrapper if Consent marketing hasn't been approved.
function hideYoutubeWrapper(hidden) {
    const wrappers = document.querySelectorAll('.hs-embed-wrapper[data-service="youtube"]');

    for (let wrapper of wrappers) {
        wrapper && hidden ? wrapper.style.display = 'none' : wrapper.style.display = 'block';
    }
};

if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing !== true) {
    hideYoutubeWrapper(true);
}

window.addEventListener('CookiebotOnAccept', function () {
    if (window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing === true) {
        hideYoutubeWrapper(false);
    }
});
