function addZoomControl(map) {
    let controlDiv = document.createElement('div');
    controlDiv.className = 'google-map-zoom-control';

    let controlWrapper = document.createElement('div');
    controlWrapper.className = 'google-map-zoom-control__inner';

    let zoomInButton = document.createElement('div');
    zoomInButton.className = 'google-map-zoom-control__button google-map-zoom-control__button--in';

    let zoomOutButton = document.createElement('div');
    zoomOutButton.className = 'google-map-zoom-control__button google-map-zoom-control__button--out';

    controlDiv.appendChild(controlWrapper);
    controlWrapper.appendChild(zoomInButton);
    controlWrapper.appendChild(zoomOutButton);

    google.maps.event.addDomListener(zoomInButton, 'click', function () {
        map.setZoom(map.getZoom() + 1);
    });

    google.maps.event.addDomListener(zoomOutButton, 'click', function () {
        map.setZoom(map.getZoom() - 1);
    });

    map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(controlDiv);
};

export default addZoomControl;
