const getElementPosition = (el, isCentered, parent) => {
    if (!el) return;
    if (!parent) parent = window;
    const parentHeight = parent.offsetHeight || window.innerHeight;
    const elHeight = el.scrollHeight;
    const heightDif = parentHeight - elHeight;
    const parentScrollTop =
        parent === window ? document.documentElement.scrollTop : parent.scrollTop;

    return isCentered
        ? el.getBoundingClientRect().top + parentScrollTop - heightDif / 2
        : el.getBoundingClientRect().top + parentScrollTop;
};

export default getElementPosition;
