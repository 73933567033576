import React from 'react';

const Geolocation = (props) => {

    const requestIpLocation = async (cb) => {
        try {
            const res = await fetch('https://ipinfo.io/geo');
            const data = await res.json();
            cb(data.loc.split(','));
        } catch (e) {
            console.warn(e);
        }
    };

    const requestGeolocation = async (e) => {
        e.preventDefault();

        if (!navigator.geolocation) {
            return requestIpLocation();
        }
        const cords = await new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve([position.coords.latitude, position.coords.longitude]);
            }, () => requestIpLocation(resolve));
        });
        props.onGeolocation(cords);
    };

    return (
        <button
            className="search-field__geolocation"
            onClick={requestGeolocation}
        />
    );
};

export default Geolocation;
