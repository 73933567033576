import { computePosition, offset, flip, shift, arrow } from '@floating-ui/dom';

const Tooltip = (el) => {
    const tooltip = el.querySelector('.tooltip');
    const tooltipContent = el.querySelector('.tooltip__content');
    const tooltipArrow = el.querySelector('.tooltip__arrow');

    const handleTooltipPosition = () => {
        computePosition(el, tooltipContent, {
            placement: 'top',
            middleware: [offset(14), flip(), shift({ padding: 5 }), arrow({ element: tooltipArrow })]
        }).then(({ x, y, placement, middlewareData }) => {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;
            const staticSide = {
                top: 'bottom',
                right: 'left',
                bottom: 'top',
                left: 'right'
            }[placement.split('-')[0]];

            Object.assign(tooltipContent.style, {
                left: `${x}px`,
                top: `${y}px`
            });

            Object.assign(tooltipArrow.style, {
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-10px'
            });

            placement === 'bottom'
                ? el.classList.add(`tooltip--align-bottom`)
                : el.classList.remove(`tooltip--align-bottom`);
        });
    };

    const show = () => {
        tooltip.classList.add('tooltip--visible');
        handleTooltipPosition();
    };

    const hide = () => {
        tooltip.classList.remove('tooltip--visible');
    };

    const addEventListeners = () => {
        el.addEventListener('mouseenter', show);
        el.addEventListener('mouseleave', hide);
    };

    addEventListeners();
};

export default Tooltip;
